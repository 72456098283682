import React from "react";

export const navList = [
  {
    id: 1,
    url: "/",
    text: "Home",
  },
  {
    id: 2,
    url: "/taggers",
    text: "Taggers",
  },
  {
    id: 3,
    url: "/rent-simple",
    text: "Rent",
  },
  // {
  //   id: 4,
  //   url: "/contact",
  //   text: "Contact",
  // },
];
